import React from 'react';

import classes from './MpgLogo.module.css';
import bigLogo from '../../../assets/Logo/bigRed.png';


const Logo = (props) => {
  return (
    <div className={classes.Logo}>
      <img src={bigLogo} alt="Futuristic Gasmask logo" />
    </div>
  );
};

export default Logo;
