import React from 'react';

import classes from './MpgText.module.css';
import fullText from '../../../assets/Logo/fullText3.png';

const MpgText = () => {
  return (
    <div className={classes.MpgText}>
      <img src={fullText} alt="MatchPoint Gaming written in circut board style" />
    </div>
  );
};

export default MpgText;
