import React from 'react';

import classes from './Contact.module.css';
import Button from '../../components/UI/Button/Button';

const Contact = () => {
  return (
    <div className={classes.Contact}>
      <div className={classes.ContactContainer}>
        <h1>Contact Us</h1>
        <form>
          <label>Name *</label>
          <input type="text" />
          <label>Email *</label>
          <input type="text" />
          <label>Message *</label>
          <textarea cols="30" rows="10"></textarea>
          <div className={classes.btnPosition}>
            <Button btnType="DangerNeon">Submit</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
