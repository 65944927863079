import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Banner.module.css';
import bannerLogo from '../../../../assets/Logo/redBanner.png';

const Banner = () => {
  return (
    <div className={classes.Logo} >
      <NavLink
        activeClassName={classes.active}
        to='/'
        exact
      >
        <img src={bannerLogo} alt="Futuristic Gasmask logo" />
      </NavLink>
    </div>
  );
};

export default Banner;
