import React from 'react';

import classes from './Toolbar.module.css';
import Banner from './Banner/Banner';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';

const Toolbar = (props) => {
  return (
    <header className={classes.Toolbar} >
      <div className={classes.Logo} >
        <Banner />
      </div>
      <DrawerToggle clicked={props.showToggle} />
      <nav className={classes.DesktopOnly} >
        <NavigationItems />
      </nav>
    </header>
  );
};

export default Toolbar;
