import React from 'react';

import classes from './TeamSpeakLogo.module.css';
import TsLogo from '../../../assets/TeamSpeak/TsLogox2.png';

const TeamSpeakLogo = () => {
  return (
    <div className={classes.Logo}>
      <img src={TsLogo} alt="TeamSpeak Logo" />
    </div>
  );
};

export default TeamSpeakLogo;
