import React, { useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import classes from './LandingPage.module.css';
import Logo from '../../components/Logos/MpgLogo/MpgLogo';
import MpgText from '../../components/Logos/MpgText/MpgText';
import TsLogo from '../../components/Logos/TeamSpeakLogo/TeamSpeakLogo';
import DcLogo from '../../components/Logos/DiscordLogo/DiscordLogo';
import Socials from '../../components/UI/Socials/Socials';
import Footer from '../../components/UI/Footer/Footer';


const LandingPage = props => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const onDiscordRedirectHanlder = () => {
    window.open('https://discord.gg/h68DunZ', '_blank');
  };

  const onTeamSpeakRedirectHandler = () => {
    window.open('http://invite.teamspeak.com/matchpointgaming', '_blank');
  };

  return (
    <div>
      <div className={classes.LandingPagePart1}>
        <div className={classes.LogoDiv}>
          <Logo />
        </div>
        <div className={classes.LogoText} >
          <MpgText />
        </div>
      </div>
      <div className={classes.LandingPagePart2Grid}>
        <div className={classes.TsDcTitle}>
          <h1>
            Join our voice channels below!
          </h1>
        </div>
        <ScrollAnimation
          className={classes.DcContent} animateIn='fadeIn'
          duration={1}
          animateOnce={true}>
          <div>
            <p>
              Discord is one of the easiest way to communicate over voice, video and text. Perfect for nigthly gaming or strategic planning with your battle buddies.
            </p>
          </div>
          <div className={classes.TsDcDiv} onClick={onDiscordRedirectHanlder}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <DcLogo />
          </div>
        </ScrollAnimation>
        <ScrollAnimation
          className={classes.TsContent} animateIn='fadeIn'
          duration={1}
          delay={250}
          animateOnce={true}>
          <div>
            <p>
              Join our server and start enjoying TeamSpeaks crystal clear sound to communicate with your team mates. High performance with unparalleled reliability and uptime.
            </p>
          </div>
          <div className={classes.TsDcDiv} onClick={onTeamSpeakRedirectHandler}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <TsLogo />
          </div></ScrollAnimation>
      </div>
      <div className={classes.LandingPagePart3}>
        <div className={classes.LpIconsDiv}>
          <Socials />
        </div>
        <div className={classes.Footer} >
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;


