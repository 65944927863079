import React from 'react';

import classes from './Socials.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faTwitch, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Socials = () => {

  const onFacebookRedirectHanlder = () => {
    window.open('https://www.facebook.com/matchpointgaming/', '_blank');
  };
  const onTwitterRedirectHanlder = () => {
    window.open('https://twitter.com/Matchpointgami1', '_blank');
  };
  const onTwitchRedirectHanlder = () => {
    window.open('https://www.twitch.tv/matchpointgaming_', '_blank');
  };
  const onYoutubeRedirectHanlder = () => {
    window.open('https://www.youtube.com/channel/UCg9LbpV5ozPjTvpw_R-dLqQ', '_blank');
  };
  const onInstagramRedirectHanlder = () => {
    window.open('https://www.instagram.com/matchpointgaming_/', '_blank');
  };

  return (
    <ul className={classes.SocialsUl}>
      <li onClick={onFacebookRedirectHanlder} >
        <FontAwesomeIcon className={classes.Icon} icon={faFacebookF} />
      </li>
      <li onClick={onTwitterRedirectHanlder} >
        <FontAwesomeIcon className={classes.Icon} icon={faTwitter} />
      </li>
      <li onClick={onTwitchRedirectHanlder} >
        <FontAwesomeIcon className={classes.Icon} icon={faTwitch} />
      </li>
      <li onClick={onYoutubeRedirectHanlder} >
        <FontAwesomeIcon className={classes.Icon} icon={faYoutube} />
      </li>
      <li onClick={onInstagramRedirectHanlder} >
        <FontAwesomeIcon className={classes.Icon} icon={faInstagram} />
      </li>
    </ul>

  );
};

export default Socials;