import React from 'react';

import classes from './Backdrop.module.css';

const Backdrop = (props) => {

  let content = props.show ? <div className={classes.Backdrop} onClick={props.clicked} ></div> : null;

  return (
    <React.Fragment>
      {content}
    </React.Fragment>
  );
};

export default Backdrop;
