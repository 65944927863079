import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import './App.css';
import "./components/UI/FontAwesomeIcons/index";
import Layout from './hoc/Layout/Layout';
import LandingPage from './containers/LandingPage/LandingPage';
import About from './components/About/About';
import Contact from './containers/Contact/Contact';

let routes = (
  <Switch>
    <Route path="/" exact component={LandingPage} />
    <Route path="/about" component={About} />
    <Route path="/contact" component={Contact} />
    <Redirect to="/" />
  </Switch>
);


const App = props => {
  return (
    <Layout>
      {routes}
    </Layout>
  );
};

export default App;
