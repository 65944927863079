import React from 'react';

import classes from './DiscordLogo.module.css';
import DcLogo from '../../../assets/Discord/DcLogo.png';

const DiscordLogo = () => {
  return (
    <div className={classes.Logo}>
      <img src={DcLogo} alt="Discord Logo" />
    </div>
  );
};

export default DiscordLogo;
