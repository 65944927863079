import React, { useEffect } from 'react';

import classes from './About.module.css';
import keyboardPic from '../../assets/stock/neonKeyboard.jpg';
import pcInside from '../../assets/stock/pcInside2.jpg';

const About = props => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className={classes.MainContainer}>
      <div className={classes.AboutContainer1}>
        <div className={classes.AboutText}>
          <h2>MPG</h2>
          <p>Our goal is to create community type platform where players are able to find anything and everything to be able to enjoy and play their favorite games.</p>
        </div>
        <div className={classes.AboutImg}>
          <img src={keyboardPic} alt="keyboard with rgb lights" />
        </div>
      </div>

      <div className={classes.AboutContainer2}>
        <div className={classes.AboutText}>
          <h2>Version 1.0</h2>
          <p>Our current version of the platform includes voice channels in TeamSpeak and Discord. Joining is easy and convient. No need to setup your own servers, just choose your preferred commucations provider and hop on one of the available channels.</p>
        </div>
        <div className={classes.AboutImg}>
          <img src={pcInside} alt="components inside computer" />
        </div>
      </div>
    </div>
  );
};

export default About;
